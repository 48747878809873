import type { GetUsersByOrganizationRoleResponseDto } from '@careos/organization-api-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export const useCollectorOptions = ({
  organizationKey,
  queryConfig,
}: {
  organizationKey: string;
  queryConfig?: QueryConfig<typeof getCollectorsQueryOptions>;
}) => {
  const { get } = useCustomFetch();

  function getCollectors(organizationKey: string) {
    return get<GetUsersByOrganizationRoleResponseDto>(
      `/config/get-collectors/${organizationKey}`,
    );
  }
  function getCollectorsQueryOptions(organizationKey: string) {
    return queryOptions({
      queryKey: ['collectors', organizationKey],
      queryFn: () => getCollectors(organizationKey),
    });
  }
  return useQuery({
    ...getCollectorsQueryOptions(organizationKey),
    ...queryConfig,
    select: (data) => data.users,
  });
};
