import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@careos/react-ui/Button';
import { Spinner } from '@careos/react-ui/Spinner';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/')({
  component: IndexComponent,
});

function IndexComponent() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    });
  };

  if (isLoading) {
    return (
      <div className="flex h-96 items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/orders" />;
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img
        src="/abclabs.svg"
        alt="abclogo"
        className="m-6 h-36 rounded-md p-4"
      />
      <p className="text-4xl font-extralight">{t('landing_page.welcome')}</p>
      <Button
        variant={'default'}
        size={'lg'}
        className="m-6"
        onClick={handleLogin}
      >
        <p className="text-lg"> {t('nav.login')} </p>
      </Button>
    </div>
  );
}
