import { Button } from '@careos/react-ui/Button';
import { Calendar } from '@careos/react-ui/Calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@careos/react-ui/Popover';
import { cn } from '@careos/react-ui/utils';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import type { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { SessionFormData } from './session-form';

type Props = {
  className?: string;
  field: ControllerRenderProps<SessionFormData, 'sessionOrder.samplingDate'>;
};

export function DatePicker({ className, field }: Props) {
  const { t } = useTranslation();
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-full pl-3 text-left font-normal',
              !field.value && 'text-muted-foreground',
            )}
          >
            {field.value ? (
              format(new Date(field.value), 'LLL dd, y')
            ) : (
              <span>{t('sessionForm.samplingDatePlaceholder')}</span>
            )}
            <CalendarIcon className="ml-auto size-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={field.value ? new Date(field.value) : undefined}
            onSelect={(date) => field.onChange(date ? date.toISOString() : '')}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
