import { useMutation } from '@tanstack/react-query';
import type { CreateSessionOrderRequestDto } from 'careos-maestro';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useCreateSession = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof createSession>;
} = {}) => {
  const { post } = useCustomFetch();

  function createSession(body: CreateSessionOrderRequestDto) {
    return post<CreateSessionOrderRequestDto>(
      '/sampling-sessions-aggregate/create-session-order',
      body,
    );
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: createSession,
  });
};
