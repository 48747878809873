import { substances } from '@careos/translations';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import svTranslation from './locales/sv/translation.json';

export const defaultNS = 'translation';
export const resources = {
  sv: {
    translation: { ...svTranslation, ...substances.sv },
  },
  en: {
    translation: { ...enTranslation, ...substances.en },
  },
} as const;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'sv', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    debug: true,
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    returnNull: false,
    defaultNS,
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
