import { IsoDateSchema, createSchemaClass } from '@abc-labs-ab/ts-events';
import { ToxicologySamplingSessionDetailsSchema } from '@careos/toxicology-types';
import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { TIME_SLOT_REGEX } from '../time-slot-regex';

export const SessionOrderSchema = z.object({
  samplingDate: IsoDateSchema,
  timeSlot: z.string().regex(TIME_SLOT_REGEX),
  sampleCount: z.number().positive(),
  samplingDetails: z.array(ToxicologySamplingSessionDetailsSchema).min(1),
});

export type SessionOrder = z.infer<typeof SessionOrderSchema>;

export const CreateSessionOrderRequestDtoSchema = z.object({
  practitionerId: nonEmptyString,
  practitionerName: nonEmptyString,
  orderId: nonEmptyString,
  sessionOrder: SessionOrderSchema,
});

export class CreateSessionOrderRequestDto extends createSchemaClass(
  CreateSessionOrderRequestDtoSchema,
) {}
