import { queryOptions, useQuery } from '@tanstack/react-query';
import type { GetWorkplaceTestingOrdersResponseDto } from 'careos-maestro';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export const useOrderData = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getOrderDataOptions>;
} = {}) => {
  const { get } = useCustomFetch();

  function getOrderData() {
    return get<GetWorkplaceTestingOrdersResponseDto>(
      '/workplace-testing-orders/get-workplace-testing-orders',
    );
  }

  function getOrderDataOptions() {
    return queryOptions({
      queryKey: ['order-data'],
      queryFn: () => getOrderData(),
    });
  }

  return useQuery({
    ...getOrderDataOptions(),
    ...queryConfig,
    select: (data) => data.workplaceTestingOrders,
  });
};
