import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@careos/react-ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuCheckboxItem,
} from '@careos/react-ui/DropdownMenu';
import { Link } from '@tanstack/react-router';
import { Mail, LogOut, Languages } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

export const TheNavBar = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('sv');

  const changeLanguage = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language.toLowerCase());
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    });
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b-2 border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 max-w-screen-2xl items-center ">
        <Link to="/">
          <img src="/abclabs.svg" alt="abclogo" className="size-[48px]" />
        </Link>
        {isAuthenticated ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                className="ml-auto overflow-hidden rounded-full"
              >
                {user?.name}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <Languages className="mr-2 size-4" />
                  {t('nav.language')}
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuCheckboxItem
                      checked={selectedLanguage === 'sv'}
                      onCheckedChange={() => changeLanguage('sv')}
                    >
                      {t('nav.swedish')}
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem
                      checked={selectedLanguage === 'en'}
                      onCheckedChange={() => changeLanguage('en')}
                    >
                      {t('nav.english')}
                    </DropdownMenuCheckboxItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>

              <DropdownMenuItem onClick={() => logout()}>
                <LogOut className="mr-2 size-4" />
                {t('nav.logout')}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem disabled>
                <Mail className="mr-2 size-4" />
                {user?.email}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <Button variant="outline" className="ml-auto" onClick={handleLogin}>
            {t('nav.login')}
          </Button>
        )}
      </div>
    </header>
  );
};
