import { Badge } from '@careos/react-ui/Badge';
import { Button } from '@careos/react-ui/Button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@careos/react-ui/Tooltip';
import type { OrderReason } from '@careos/toxicology-types';
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table';
import type { OrderStatus, WorkplaceTestingOrder } from 'careos-maestro';
import { format } from 'date-fns';
import { CalendarPlus, EyeOff } from 'lucide-react';

import i18n from '@/i18n';

const columnHelper = createColumnHelper<WorkplaceTestingOrder>();

const getSeverity = (status: OrderStatus) => {
  switch (status) {
    case 'requested':
      return 'bg-red-500';
    case 'assigned':
      return 'bg-yellow-500';
    case 'session_started':
      return 'bg-blue-500';
    case 'session_closed':
      return 'bg-green-500';
    default:
      return 'bg-gray-500';
  }
};

export const createColumns = (
  handleOpenDialog: (order: WorkplaceTestingOrder) => void,
): ColumnDef<WorkplaceTestingOrder, any>[] => [
  columnHelper.display({
    id: 'expander',
  }),
  columnHelper.accessor('orderDate', {
    header: i18n.t('table.header.order_date'),
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('samplingPeriod', {
    header: i18n.t('table.header.sampling_period'),
    cell: ({ getValue }) => {
      const { start, end } = getValue();
      const formattedStartDate = format(new Date(start), 'yyyy-MM-dd');
      const formattedEndDate = format(new Date(end), 'yyyy-MM-dd');

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
  }),
  columnHelper.accessor('orderId', {
    header: i18n.t('table.header.order_id'),
    cell: ({ getValue }) => getValue().slice(0, 6),
  }),
  columnHelper.accessor('requester.name', {
    header: i18n.t('table.header.organization'),
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('requester.workplace', {
    header: i18n.t('table.header.employer'),
    cell: ({ getValue }) => getValue(),
  }),
  columnHelper.accessor('reasonForTesting', {
    header: i18n.t('table.header.reason_for_testing'),
    cell: ({ getValue }) => {
      return i18n.t(`reason.${getValue<OrderReason>()}`);
    },
  }),
  columnHelper.accessor('notes', {
    header: i18n.t('table.header.more_information'),
    cell: ({ getValue }) => getValue() ?? '-',
  }),
  columnHelper.display({
    id: 'actions',
    header: i18n.t('table.header.actions'),
    cell: ({ row, table }) => {
      const reasonForTesting = row.getValue<OrderReason>('reasonForTesting');
      const orderId = row.getValue('orderId') as string;

      // Function to get the full order data when needed
      const getOrder = (): WorkplaceTestingOrder | undefined => {
        const orderIndex = table.options.data.findIndex(
          (order: WorkplaceTestingOrder) => order.orderId === orderId,
        );
        return orderIndex !== -1
          ? (table.options.data[orderIndex] as WorkplaceTestingOrder)
          : undefined;
      };

      const order = getOrder();
      if (!order) return;

      if (reasonForTesting === 'random_selection' || !order.sessions?.length) {
        return (
          <div className="flex">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleOpenDialog(order)}
                  >
                    <CalendarPlus className="size-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{i18n.t('table.create_session')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Button variant="ghost" size="icon">
              <EyeOff className="size-4 text-gray-500 opacity-70" />
            </Button>
          </div>
        );
      } else {
        return <span>{order.sessions[0].sessionId.slice(0, 6)}</span>;
      }
    },
  }),
  columnHelper.accessor('orderStatus', {
    header: i18n.t('table.header.status'),
    cell: ({ getValue }) => {
      const severity = getSeverity(getValue());
      return (
        <Badge className={`font-bold ${severity}`} variant={'static'}>
          {i18n.t(`status.${getValue<OrderStatus>()}`)}
        </Badge>
      );
    },
  }),
];
